"use client";
import { jsx as l, jsxs as v, Fragment as B } from "react/jsx-runtime";
import { Avatar as C } from "../../Avatar/Avatar.js";
import { Loading as P } from "../../Loading/Loading.js";
import { MessagingStatus as E } from "../messaging/types.js";
import { useMessageContainerSelector as b } from "./MessageContainerContext.js";
import { cn as w, timestampToString as D } from "@deputy-ai/common";
import { cva as V } from "/home/runner/work/deputy.ai/deputy.ai/web/node_modules/.pnpm/class-variance-authority@0.7.0/node_modules/class-variance-authority/dist/index.mjs";
import { differenceInMinutes as _ } from "date-fns";
import { useMemo as p } from "react";
import { MdError as j } from "react-icons/md";
const h = 10;
function W(n) {
  const { message: t, prevMessage: r, nextMessage: o, showTime: i } = n, s = b(
    (e) => e.messageBubblePosition
  ), u = b(
    (e) => e.renderMessageContent
  ), c = b(
    (e) => e.renderMessageFooter
  ), m = b((e) => e.resendMessage), f = b((e) => e.showName), a = p(() => (typeof s == "function" ? s(t) : s) ?? "left", [t]), d = p(() => k(t, r, o), [r, t, o]), I = p(() => (typeof f == "function" ? f(t) : f) ?? (d === "first" || d === "sole"), [t, f, d]), g = p(() => typeof i == "boolean" ? i : r ? x(t, r) > h : !0, [t, r, o]), N = (() => {
    const e = u == null ? void 0 : u(t), T = t.status === E.Sending && /* @__PURE__ */ l(
      P,
      {
        className: w(
          "absolute h-6 w-6",
          a === "right" ? y.status : S.status
        )
      }
    ), A = t.status === E.Failed && /* @__PURE__ */ l(
      j,
      {
        onClick: () => m == null ? void 0 : m(t),
        className: w(
          "absolute h-6 w-6 text-destructive cursor-pointer",
          a === "right" ? y.status : S.status
        )
      }
    );
    return !e && !T && !A ? null : /* @__PURE__ */ v(B, { children: [
      e,
      T,
      A
    ] });
  })();
  return /* @__PURE__ */ v(B, { children: [
    g && t.eventTime && /* @__PURE__ */ l("div", { className: "text-sm text-center text-gray-500 my-4", children: D(t.eventTime) }),
    /* @__PURE__ */ v(
      "div",
      {
        className: w(
          "flex flex-row gap-2",
          a === "right" && "flex-row-reverse"
        ),
        children: [
          /* @__PURE__ */ l(
            F,
            {
              message: t,
              avatar: t.avatarSrc,
              displayName: t.name,
              order: d
            }
          ),
          /* @__PURE__ */ v(
            "div",
            {
              className: L({
                order: d
              }),
              children: [
                I && /* @__PURE__ */ l("div", { className: "text-primary-600 leading-none", children: t.name }),
                N && /* @__PURE__ */ l(G, { position: a, order: d, children: N }),
                c == null ? void 0 : c(t)
              ]
            }
          )
        ]
      }
    )
  ] });
}
function x(n, t) {
  const r = t == null ? void 0 : t.eventTime, o = n == null ? void 0 : n.eventTime;
  return !r || !o ? 0 : Math.abs(_(o, r));
}
function k(n, t, r) {
  var u, c, m;
  const o = (u = t == null ? void 0 : t.participant) == null ? void 0 : u.externalId, i = (c = n == null ? void 0 : n.participant) == null ? void 0 : c.externalId, s = (m = r == null ? void 0 : r.participant) == null ? void 0 : m.externalId;
  if (i !== o && i === s)
    return x(n, r) > h ? "sole" : "first";
  if (i === o && i !== s)
    return x(n, t) > h ? "sole" : "last";
  if (i === o && i === s) {
    const f = x(n, t), a = x(n, r);
    return f > h ? a > h ? "sole" : "first" : a > h ? "last" : "middle";
  }
  return "sole";
}
const F = ({
  message: n,
  avatar: t,
  order: r,
  displayName: o
}) => {
  const i = b((c) => c.showAvatar), s = typeof i == "function" ? i(n, r) : i;
  return s === "null" ? null : s ?? (r === "first" || r === "sole") ? /* @__PURE__ */ l(
    C,
    {
      className: "flex-shrink-0",
      imgUrl: t,
      text: o == null ? void 0 : o.substring(0, 1),
      size: 40
    }
  ) : /* @__PURE__ */ l("span", { className: "w-10" });
}, G = ({ position: n, order: t, children: r }) => {
  const o = p(() => n === "left" ? S : y, [n]);
  return /* @__PURE__ */ l("div", { className: w(o.container, o[t]), children: r });
}, L = V("flex flex-col gap-2", {
  variants: {
    order: {
      first: "mb-1",
      middle: "mb-1",
      last: "mb-4",
      sole: "mb-4"
    }
  }
}), S = {
  container: "px-4 py-2.5 rounded-xl bg-gray-200 mr-10 relative",
  status: "-right-8 bottom-2",
  first: "rounded-bl-sm",
  middle: "rounded-l-sm",
  last: "rounded-tl-sm",
  sole: ""
}, y = {
  container: "px-4 py-2.5 rounded-xl bg-blue-100 ml-10 relative",
  status: "-left-8 bottom-2",
  first: "rounded-br-sm",
  middle: "rounded-r-sm",
  last: "rounded-tr-sm",
  sole: ""
};
export {
  W as MessagingBubble,
  h as SPLIT_MESSAGE_GAP_MINUTE
};
