"use client";
import { jsxs as F, jsx as p } from "react/jsx-runtime";
import "../MessagePanelV2/MessagePanel.js";
import { MessageInput as _ } from "../MessagePanelV2/components/MessageInput/index.js";
import "@deputy-ai/api/api";
import "@deputy-ai/api/api/gen/models/MessageParticipantType";
import "@deputy-ai/api/model/message";
import "@deputy-ai/api/model/visitor";
import { forwardRef as $, useState as S, useRef as L, useMemo as O } from "react";
import "uuid";
import "../realtime/PusherProvider.js";
import "@deputy-ai/api/api/gen/models/TaskRealm";
import "@deputy-ai/api/api/singleton";
import "@deputy-ai/api/api/sse";
import "@deputy-ai/api/model/response";
import "@deputy-ai/api/api/request";
import "@deputy-ai/api/model/list";
import "ahooks";
import { MessageContainer as W } from "./components/MessageContainer.js";
import { useLoadMessages as B } from "./hooks/useLoadMessages.js";
import { usePullSSEResult as G } from "./hooks/usePullSSEResult.js";
import { useRealtimeMessage as H } from "./hooks/useRealtimeMessage.js";
import { MessagingEventType as f, MessagingMessageType as J, MessagingChannelType as K, MessagingStatus as M } from "./messaging/types.js";
import { toMessageMeta as Q } from "./messaging/utils.js";
import { cn as V } from "@deputy-ai/common";
import { uniqueId as w } from "lodash";
const X = $(
  (I, Y) => {
    const {
      conversationId: o,
      installedApp: t,
      user: u,
      listMessagesApi: b,
      sendMessageApi: x,
      uploadImageApi: C,
      showAvatar: s,
      renderMessageContent: k = (e) => e.payload.message,
      messageBubblePosition: r,
      className: N
    } = I, [R, E] = S(""), [P, U] = S(), v = L(null), { messages: q, loadMore: j, appendMessage: a, removeMessage: d } = B({
      request: b,
      deps: {
        installedAppUid: t == null ? void 0 : t.uid,
        orgUid: t == null ? void 0 : t.orgUid,
        conversationUid: o
      },
      combineDeps: (e) => ({
        path: {
          installedAppUid: e == null ? void 0 : e.installedAppUid,
          orgUid: e == null ? void 0 : e.orgUid,
          conversationUid: e == null ? void 0 : e.conversationUid
        },
        query: {
          page: e == null ? void 0 : e.page,
          pageSize: e == null ? void 0 : e.pageSize
        }
      }),
      onSuccess: () => {
        setTimeout(
          () => {
            var e;
            return (e = v.current) == null ? void 0 : e.scrollToEnd({ force: !0 });
          },
          0
        );
      }
    }), l = async (e) => {
      const { orgUid: n, uid: g } = t ?? {};
      if (!n || !g)
        return;
      const c = w(), m = {
        uid: c,
        orgUid: n,
        conversationUid: o,
        installedAppUid: g,
        eventType: f.CustomerMessage,
        payload: {
          message: e,
          messageType: J.Text,
          channel: K.Web
        },
        participant: u,
        status: M.Sending,
        eventTime: (/* @__PURE__ */ new Date()).toISOString()
      };
      return a(m), await x({
        body: {
          orgUid: n,
          installedAppUid: g,
          conversationUid: o,
          message: {
            conversationUid: o,
            installedAppUid: g,
            eventType: "",
            payload: {
              message: e
            },
            participant: u
          }
        },
        path: {
          orgUid: n,
          installedAppUid: g,
          conversationUid: o
        }
      }).then((i) => {
        var y, h, T;
        (y = i == null ? void 0 : i.data) != null && y.message && (d(c), a((h = i == null ? void 0 : i.data) == null ? void 0 : h.message)), i != null && i.error && (d(c), m.status = M.Failed, a(m)), E(((T = i == null ? void 0 : i.data) == null ? void 0 : T.taskUid) ?? "");
      }).catch(() => {
        d(c), m.status = M.Failed, a(m);
      }), await Promise.resolve();
    };
    G({
      appendMessage: (e) => {
        t != null && t.uid && a({
          ...e,
          installedAppUid: t == null ? void 0 : t.uid,
          conversationUid: o,
          uid: w(),
          orgUid: t == null ? void 0 : t.orgUid,
          eventType: f.Ask
        });
      },
      setPendingMessage: (e) => {
        if (!e || !(t != null && t.uid) || !o) {
          U(void 0);
          return;
        }
        U({
          ...e,
          conversationUid: o,
          orgUid: t == null ? void 0 : t.orgUid,
          eventType: f.Ask,
          installedAppUid: t.uid
        });
      },
      taskId: R,
      realm: "human_agent"
    });
    const z = O(() => {
      var e;
      return `conversation.agent-${(e = t == null ? void 0 : t.settings) == null ? void 0 : e.agent_id}.${o}`;
    }, [t]);
    H({
      channelId: z,
      appendMessage: (e) => {
        e.participant.type !== u.type && a(e);
      }
    });
    const D = (e) => {
      e.uid && (d(e.uid), l(e.payload.message));
    };
    return /* @__PURE__ */ F("div", { className: V("h-[600px] w-96 overflow-hidden relative", N), children: [
      /* @__PURE__ */ p(
        W,
        {
          ref: v,
          className: "pb-12",
          onRefresh: j,
          messages: q,
          getMessageMeta: (e) => Q(e),
          renderMessageContent: ({ origin: e }) => k(e),
          showAvatar: typeof s == "function" ? ({ origin: e }, n) => s == null ? void 0 : s(e, n) : s,
          showName: !1,
          resendMessage: ({ origin: e }) => D(e),
          messageBubblePosition: ({ origin: e }) => typeof r == "function" ? r == null ? void 0 : r(e) : r,
          pendingMessage: P
        }
      ),
      /* @__PURE__ */ p("div", { className: "absolute -bottom-2 w-full", children: /* @__PURE__ */ p(
        _,
        {
          uploadImageApi: C,
          sendMessage: async (e) => await l(e.preview)
        }
      ) })
    ] });
  }
);
X.displayName = "Chatbox";
export {
  X as Chatbox
};
