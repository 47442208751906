"use client";
import { jsx as t, jsxs as N } from "react/jsx-runtime";
import { Tooltip as V } from "../../tooltip/Tooltip/Tooltip.js";
import { cn as a } from "@deputy-ai/common";
import { Slot as E } from "@radix-ui/react-slot";
import { cva as f } from "class-variance-authority";
import * as n from "react";
import { AiOutlineLoading3Quarters as j } from "react-icons/ai";
const B = f(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary-800 text-primary-foreground shadow hover:bg-primary-800/90",
        success: "bg-success text-success-foreground shadow-sm hover:bg-success/80",
        successLight: "bg-success-light text-success-foreground shadow-sm hover:bg-success-light/80",
        destructive: "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/80",
        destructiveLight: "bg-destructive-light text-destructive-foreground shadow-sm hover:bg-destructive-light/80",
        outline: "border border-input bg-transparent shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/70",
        ghost: "hover:bg-accent",
        link: "text-primary underline-offset-4 hover:underline"
      },
      size: {
        xs: "h-7 text-xs gap-x-1",
        sm: "h-8 text-xs gap-x-1",
        default: "h-9 gap-x-2",
        lg: "h-10 gap-x-3"
      },
      iconSize: {
        false: "",
        true: ""
      },
      rounded: {
        false: "",
        true: "rounded-full"
      }
    },
    compoundVariants: [
      { size: "xs", iconSize: !1, class: "px-2" },
      { size: "sm", iconSize: !1, class: "px-3" },
      { size: "default", iconSize: !1, class: "px-4" },
      { size: "lg", iconSize: !1, class: "px-4" },
      { size: "xs", iconSize: !0, class: "w-7" },
      { size: "sm", iconSize: !0, class: "w-8" },
      { size: "default", iconSize: !0, class: "w-9" },
      { size: "lg", iconSize: !0, class: "w-10" }
    ],
    defaultVariants: {
      variant: "default",
      size: "default",
      iconSize: !1
    }
  }
), d = f("", {
  variants: {
    size: {
      default: "w-6 h-6",
      xs: "w-4 h-4",
      sm: "w-5 h-5",
      lg: "w-6 h-6"
    }
  },
  defaultVariants: {
    size: "default"
  }
}), C = n.forwardRef(
  ({
    className: m,
    variant: g,
    size: r,
    asChild: h = !1,
    Icon: i,
    icon: e,
    iconSize: p,
    loading: c,
    disabled: l,
    rounded: x,
    tooltip: s,
    children: u,
    ...v
  }, b) => {
    const z = h ? E : "button", w = !!(!u && (e ?? i)), y = n.isValidElement(e) ? n.cloneElement(e, {
      className: a(
        d({
          size: r,
          className: e.props.className
        })
      )
    }) : i ? /* @__PURE__ */ t(
      i,
      {
        className: a(
          d({
            size: r
          })
        )
      }
    ) : c ? /* @__PURE__ */ t(j, { className: "animate-spin" }) : e, o = /* @__PURE__ */ N(
      z,
      {
        className: a(
          B({
            variant: g,
            size: r,
            iconSize: p ?? w,
            rounded: x,
            className: m
          })
        ),
        disabled: l ?? c,
        ref: b,
        ...v,
        children: [
          y,
          u
        ]
      }
    );
    if (!s)
      return o;
    const S = {
      ...typeof s == "string" ? { content: s } : s
    };
    return /* @__PURE__ */ t(V, { ...S, children: l ? /* @__PURE__ */ t("span", { tabIndex: 0, children: o }) : o });
  }
);
C.displayName = "Button";
export {
  C as Button,
  B as buttonVariants
};
